import * as tracking from 'dibs-tracking';
import serverVars from 'server-vars';
import { BROWSE_TYPES, PAGE_TYPE } from 'dibs-constants/exports/pageTypes';
import { getLCP, getFID, getTTFB, Metric } from 'web-vitals';

const PERF_METRIC_BROWSER_FCP = 'FCP';
const PERF_METRIC_BROWSER_LCP = 'LCP';
const PERF_METRIC_BROWSER_FID = 'FID';
const PERF_METRIC_BROWSER_TTFB = 'TTFB';
const PERF_METRIC_BROWSER_CLS = 'CLS';
const PERF_METRIC_BROWSER_INITIAL_CLS = 'INITIAL_CLS';

/**
 * Configuration objects to determine what will be uploaded to Google Analytics
 * and Datadog.
 */
const UPLOAD_TO_GA = [
    PERF_METRIC_BROWSER_FCP,
    PERF_METRIC_BROWSER_LCP,
    PERF_METRIC_BROWSER_FID,
    PERF_METRIC_BROWSER_CLS,
    PERF_METRIC_BROWSER_INITIAL_CLS,
    PERF_METRIC_BROWSER_TTFB,
];
/**
 * GA limits how many samples they will process daily, therefore, we're only
 * firing performance metrics for these page types to more quickly gather data
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings#sampling_considerations
 */
const UPLOAD_TO_GA_PAGETYPES = [PAGE_TYPE.HOME, PAGE_TYPE.PDP, ...BROWSE_TYPES, PAGE_TYPE.SEARCH];

type WebVitalsMetric = Pick<Metric, 'id' | 'delta'> & {
    name:
        | typeof PERF_METRIC_BROWSER_FCP
        | typeof PERF_METRIC_BROWSER_LCP
        | typeof PERF_METRIC_BROWSER_FID
        | typeof PERF_METRIC_BROWSER_CLS
        | typeof PERF_METRIC_BROWSER_INITIAL_CLS
        | typeof PERF_METRIC_BROWSER_TTFB;
};

function shouldUploadToAnalytics(name: string): boolean {
    const pageType = serverVars.get('settings.pageType');
    return (
        !serverVars.get('enablers.disableGaPerformanceTracking') &&
        UPLOAD_TO_GA.includes(name) &&
        UPLOAD_TO_GA_PAGETYPES.includes(pageType)
    );
}

const supportsPerformanceObserver =
    typeof window === 'object' &&
    window.PerformanceObserver &&
    !!window.PerformanceObserver.supportedEntryTypes;

// Know when the entry types we would like to use are not supported.
function isSupportedPerfEntryType(entryTypes: string[]): boolean {
    for (const entryType of entryTypes) {
        if (!window.PerformanceObserver.supportedEntryTypes.includes(entryType)) {
            return false;
        }
    }
    return true;
}

// Performantly generate a unique, 27-char string by combining the current
// timestamp with a 13-digit random number.
function generateUniqueID(): string {
    return `${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
}

function trackWebVitals({ delta, name, id }: WebVitalsMetric): void {
    if (shouldUploadToAnalytics(name)) {
        tracking.trackWebVitalsEvent({
            action: name,
            value: Math.round(
                name === PERF_METRIC_BROWSER_CLS || name === PERF_METRIC_BROWSER_INITIAL_CLS
                    ? delta * 1000
                    : delta
            ),
            label: id,
        });
    }
}

function getInitialCLS(reportCallback: (data: WebVitalsMetric) => void): void {
    try {
        const handleDocumentComplete = (): void => {
            if (document.readyState === 'complete') {
                const clsObserver = new PerformanceObserver(entryList => {
                    const delta = entryList.getEntries().reduce(
                        (
                            value,
                            // entry in this case should be LayoutShift type which is not in place yet
                            // @see https://wicg.github.io/layout-instability/#sec-layout-shift
                            entry: $TSFixMe
                        ) => {
                            // Only count layout shifts without recent user input.
                            if (!entry.hadRecentInput) {
                                return (value += entry.value);
                            }
                            return value;
                        },
                        0
                    );

                    reportCallback({
                        name: PERF_METRIC_BROWSER_INITIAL_CLS,
                        id: generateUniqueID(),
                        delta,
                    });
                    // clean up listeners
                    document.removeEventListener('readystatechange', handleDocumentComplete);
                    clsObserver.disconnect();
                });

                clsObserver.observe({
                    type: 'layout-shift',
                    buffered: true,
                });
            }
        };

        document.addEventListener('readystatechange', handleDocumentComplete);
    } catch (e) {
        // Do nothing if the browser doesn't support this API.
    }
}

export function initCustomPerformanceReporting(): void {
    if (!supportsPerformanceObserver) {
        return;
    }

    getFID(trackWebVitals);
    getLCP(trackWebVitals);
    getTTFB(trackWebVitals);

    if (isSupportedPerfEntryType(['layout-shift'])) {
        getInitialCLS(trackWebVitals);
    }
}

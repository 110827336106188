import { fetchQuery, Environment, graphql } from 'react-relay';
import serverVars from 'server-vars';

import { getLang } from 'dibs-intl/exports/languages';
import { LOCALE_US } from 'dibs-intl/exports/locales';

import { createSfChatContainer, getSfChatContainer } from './helpers/sfChatContainer';
import { SALESFORCE_CHAT_KEY, DEFAULT_CHAT_LOAD_DELAY_MS, CHAT_TYPE_MAP } from './constants';
import { existingChatLoaded } from '../tracking';

import { initSalesforceChatQuery as Query } from './__generated__/initSalesforceChatQuery.graphql';

type LiveChatType = (typeof CHAT_TYPE_MAP)[keyof typeof CHAT_TYPE_MAP];

export const initSalesforceChatQuery = graphql`
    query initSalesforceChatQuery($isSeller: Boolean!, $isCheckout: Boolean!) {
        viewer {
            contact1stdibsConfig(isSeller: $isSeller) @skip(if: $isCheckout) {
                chatConfig {
                    salesForceHost
                    salesForcePartnersHost
                    chatButtonId
                    chatDeploymentId
                    chatBaseLiveAgentContentUrl
                    chatLiveAgentUrl
                    salesForceOrgId
                    liveAgentDevName
                }
            }
            checkoutLiveChatConfig @include(if: $isCheckout) {
                chatConfig {
                    salesForceHost
                    salesForcePartnersHost
                    chatButtonId
                    chatDeploymentId
                    chatBaseLiveAgentContentUrl
                    chatLiveAgentUrl
                    salesForceOrgId
                    liveAgentDevName
                }
            }
        }
    }
`;

const isChatBoxFocused = (): boolean => {
    const chatContainer = document.getElementsByClassName(
        'dockableContainer showDockableContainer'
    )[0];
    const activeElement = document.activeElement;

    return chatContainer && chatContainer.contains(activeElement);
};

export const initChat = async ({
    chatType,
    environment,
    initiated = false,
    afterDestroy,
    onChasitorMessage,
}: {
    chatType: LiveChatType;
    environment: Environment;
    initiated?: boolean;
    afterDestroy?: () => void;
    onChasitorMessage?: () => void;
}): Promise<void> => {
    // if startChat function already exists on liveAgentAPI it means that embedded chat was already initiated
    if (typeof window.embedded_svc?.liveAgentAPI?.startChat === 'function') {
        return;
    }

    createSfChatContainer();

    // after page reload, embedded_svc is not loaded instantly
    // we need to set internal to wait until embedded_svc is available
    const interval = setInterval(async () => {
        if (window.embedded_svc && !window.embedded_svc.liveAgentAPI) {
            if (!initiated) {
                existingChatLoaded();
            }

            const language = getLang(serverVars.get('locale') || LOCALE_US);
            const isCheckout = chatType === CHAT_TYPE_MAP.checkout;

            const response = await fetchQuery<Query>(environment, initSalesforceChatQuery, {
                isSeller: chatType === CHAT_TYPE_MAP.seller,
                isCheckout,
            });
            const { viewer } = (await response.toPromise()) || {};
            const { contact1stdibsConfig, checkoutLiveChatConfig } = viewer || {};
            const config = isCheckout ? checkoutLiveChatConfig : contact1stdibsConfig;
            const {
                salesForceHost,
                salesForcePartnersHost,
                chatButtonId,
                chatDeploymentId,
                chatBaseLiveAgentContentUrl,
                chatLiveAgentUrl,
                salesForceOrgId,
                liveAgentDevName,
            } = config?.chatConfig || {};

            window.embedded_svc.settings.targetElement = getSfChatContainer();
            window.embedded_svc.settings.displayHelpButton = false;
            window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
            window.embedded_svc.settings.entryFeature = 'LiveAgent';
            window.embedded_svc.settings.language = language;
            window.embedded_svc.settings.allowGuestUsers = isCheckout;

            if (initiated) {
                window.embedded_svc.addEventHandler('onChatEstablished', () => {
                    sessionStorage.setItem(SALESFORCE_CHAT_KEY, 'true');
                    window.dispatchEvent(new Event('sfChatEstablished'));
                });

                if (typeof onChasitorMessage === 'function') {
                    window.embedded_svc.addEventHandler('onChasitorMessage', () => {
                        onChasitorMessage();
                    });
                }
            }

            window.embedded_svc.addEventHandler('onChatEndedByChasitor', () => {
                sessionStorage.removeItem(SALESFORCE_CHAT_KEY);
            });

            window.embedded_svc.addEventHandler('onChatEndedByAgent', () => {
                sessionStorage.removeItem(SALESFORCE_CHAT_KEY);
            });

            window.embedded_svc.addEventHandler('onConnectionError', () => {
                sessionStorage.removeItem(SALESFORCE_CHAT_KEY);
            });

            if (typeof afterDestroy === 'function') {
                window.embedded_svc.addEventHandler('afterDestroy', () => {
                    afterDestroy();
                    window.dispatchEvent(new Event('sfChatDestroyed'));
                });
            }

            // sound effect on agent message implementation
            const soundEffect = new Audio();

            const playEmptySound = (): void => {
                soundEffect.play();
                document.removeEventListener('click', playEmptySound);
            };

            // this is a workaround for sound to be autoplayed in safari
            // safari won't play sound if it's not happening after user interaction
            // so here user interaction is imitated with adding onClick event listener to a document
            // and after sound is played after user interaction it later can be autoplayed without it
            if (document) {
                document.addEventListener('click', playEmptySound, { once: true });
            }

            window.embedded_svc.addEventHandler('onAgentMessage', () => {
                // sound should only play if chat is minimized or chat window is not focused
                if (isChatBoxFocused()) {
                    return;
                }

                soundEffect.src =
                    'https://a.1stdibscdn.com/dist/adhoc/audio/support-chat-sound.mp3';
                soundEffect.play();
            });

            window.embedded_svc.init(
                salesForceHost,
                salesForcePartnersHost,
                'https://service.force.com',
                salesForceOrgId,
                chatType,
                {
                    baseLiveAgentContentURL: chatBaseLiveAgentContentUrl,
                    deploymentId: chatDeploymentId,
                    buttonId: chatButtonId,
                    baseLiveAgentURL: chatLiveAgentUrl,
                    eswLiveAgentDevName: liveAgentDevName,
                    isOfflineSupportEnabled: false,
                }
            );
            clearInterval(interval);
        }
    }, DEFAULT_CHAT_LOAD_DELAY_MS);
};

export default { initChat, SALESFORCE_CHAT_KEY, CHAT_TYPE_MAP, DEFAULT_CHAT_LOAD_DELAY_MS };

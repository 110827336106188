import { getGtmEventQueue } from './gtmEventQueue';
import { eventTypeConstants } from './constants';

type PerfWebVital = {
    label: string;
    value: number;
    action: string;
};

export function trackWebVitalsEvent(trackObj?: PerfWebVital): void {
    if (
        trackObj &&
        trackObj.label &&
        typeof trackObj.value === 'number' &&
        !isNaN(trackObj.value)
    ) {
        const gtmEventQueue = getGtmEventQueue();
        const gtmData = {
            category: 'Web Vitals',
            event: eventTypeConstants.EVENT_PERF_WEB_VITALS,
            action: trackObj.action, // metric name
            value: trackObj.value, // metric measurement
            label: trackObj.label, // unique ID for current page load
        };

        gtmEventQueue.push(gtmData);
    }
}

import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';
import { getBuyerId } from 'dibs-cookie-jar';
import { VisitCountModel } from 'dibs-visit-tracking/exports/visitCountModel';
import { KEY_SESSION_COUNT } from 'dibs-visit-tracking/exports/visitCountConstants';

const REGISTRATION_PV_CADENCE_TEST_NAME = 'RegistrationPVCadence_Nov2024';

export const getRegistrationPVCadenceTestVariant = (): string | undefined =>
    getAbTestV2(REGISTRATION_PV_CADENCE_TEST_NAME)?.variant;

export const getTestVisitsPerSessionLoginPrompt = (): number | undefined => {
    switch (getRegistrationPVCadenceTestVariant()) {
        case 'variant-1':
            return 2;
        case 'variant-2':
            return 3;
        case 'variant-3':
            return 4;
        default:
            return undefined;
    }
};

export const trackRegistrationPVCadenceTest = (): void => {
    if (!getBuyerId(document.cookie)) {
        const visitCountModel = new VisitCountModel();

        // Track A/B test for then first visit in the session.
        if (visitCountModel.get(KEY_SESSION_COUNT) === 1) {
            trackAbTestV2Variant(REGISTRATION_PV_CADENCE_TEST_NAME);
        }
    }
};

/* eslint-disable no-console */
import serverVars from 'server-vars';

// re-exporting so consumers can use the same constants without importing from dibs-monitoring
export * from 'dibs-monitoring/exports/monitoringGroups';

import {
    type VALID_MONITORING_GROUP_VALUES,
    isValidMonitoringGroup,
    VALID_LABEL_VALUES_MONITORING_GROUP,
} from 'dibs-monitoring/exports/monitoringGroups';

const PREFIX = 'dibs/';
const START = '/start';
const END = '/end';
export const PAGE_INIT_MARK = 'page_init';
let observer: PerformanceObserver | null = null;

export type DibsMark = `${typeof PREFIX}${string}${typeof START | typeof END}`;

export type PerfSubGroup = {
    (mark: string | string[]): void;
    getMark: (mark: string) => DibsMark;
};

const perfMarkFactory = (suffix: typeof START | typeof END): PerfSubGroup => {
    const getMark = (mark: string): DibsMark => `${PREFIX}${mark}${suffix}`;
    const setMark = (mark: string | string[]): void => {
        if (observer === null) {
            // can't track if the observer hasn't been initialized
            console.error(
                'Performance tracking has not been initialized! Call initPerformanceTracking first'
            );
            return;
        }
        mark = Array.isArray(mark) ? mark : [mark];
        mark.forEach(m => {
            performance.mark(getMark(m));
        });
    };
    setMark.getMark = getMark;
    return setMark;
};
export const perfSubGroupStart = perfMarkFactory(START);
export const perfSubGroupEnd = perfMarkFactory(END);

export const perfMeasure = ({
    startMark,
    subGroup,
    monitoringGroup,
}: {
    startMark?: string;
    subGroup: string;
    monitoringGroup: VALID_MONITORING_GROUP_VALUES;
}): void => {
    if (!isValidMonitoringGroup(monitoringGroup)) {
        console.error(
            `Invalid monitoring group: ${monitoringGroup}; use one of ${VALID_LABEL_VALUES_MONITORING_GROUP}`
        );
        return;
    }
    const start = perfSubGroupStart.getMark(startMark ?? subGroup);
    const end = perfSubGroupEnd.getMark(subGroup);
    if (performance.getEntriesByName(start).length === 0) {
        console.error(
            `subGroup start mark not found: ${start}. Must call perfSubGroupStart first. Analytics not sent.`
        );
        return;
    }
    try {
        performance.measure(PREFIX + subGroup, {
            start,
            // performance.measure will error if end mark hasn't been set but undefined is valid
            end: performance.getEntriesByName(end).length > 0 ? end : void 0,
            detail: { monitoringGroup },
        });
    } catch (e) {
        console.log(`Error measuring performance: ${e}`);
    }
};

export const perfMeasureFromPageInit = ({
    monitoringGroup,
    subGroup,
}: {
    subGroup: string;
    monitoringGroup: VALID_MONITORING_GROUP_VALUES;
}): void => {
    perfMeasure({
        startMark: PAGE_INIT_MARK,
        subGroup,
        monitoringGroup,
    });
};

const useAnalyticsEndpoint = serverVars.get('NODE_PROM_ANALYTICS_ENDPOINT') === 'true';
const queryPath = 'soa/graphql/persisted/analytics/v1/trackClientLatencyMutation';
const sendPerfAnalytics = (entry: PerformanceMeasure): void => {
    if (useAnalyticsEndpoint && typeof fetch !== 'undefined' && location) {
        const data = JSON.stringify({
            variables: {
                input: {
                    monitoringGroup: entry.detail?.monitoringGroup,
                    subGroup: entry.name.replace(PREFIX, ''),
                    latency: Number((entry.duration / 1000).toFixed(3)), // prom-client expects seconds
                },
            },
        });
        fetch(`${location.protocol}//${location.host}/${queryPath}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            // see https://developer.mozilla.org/en-US/docs/Web/API/fetch#keepalive
            keepalive: true,
            body: data,
        }).catch(e => console.log(`Error sending performance analytics: ${e}`));
    }
};

/**
 * @description This function initializes the performance tracking by setting up a
 * PerformanceObserver to listen for performance measures. It should only be called
 * in an entry file prior to any performance measures being taken or marks being set.
 * It MUST be called prior to other initilization such as initializeDBL.
 */
export const initPerformanceTracking = (): void => {
    // We only process a measure once to avoid sending duplicate analytics
    // There are often double renders which would cause duplicate measures
    const processed = new Set<string>();
    function listObservor(list: PerformanceObserverEntryList): void {
        for (const entry of list.getEntries()) {
            if (entry.name.startsWith(PREFIX) && !processed.has(entry.name)) {
                sendPerfAnalytics(entry as PerformanceMeasure);
                processed.add(entry.name);
            }
        }
    }
    if (typeof PerformanceObserver !== 'undefined' && !observer) {
        observer = new PerformanceObserver(listObservor);
        observer.observe({ entryTypes: ['measure'] });
    }
    perfSubGroupStart(PAGE_INIT_MARK);
};

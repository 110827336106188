/**
 * @generated SignedSource<<b9e2f417bb94986afedc1c4197f7e87f>>
 * @relayHash b93149f9c6f2580a67ad258025d2af0e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/491.0.0-2024-12-04T08:42:30.904Z/verifyEmailHandlerMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TransactionVerifyEmailMutationInput = {
  clientMutationId?: string | null;
  emailVerificationToken: string;
};
export type verifyEmailHandlerMutation$variables = {
  input: TransactionVerifyEmailMutationInput;
};
export type verifyEmailHandlerMutation$data = {
  readonly transactionVerifyEmail: {
    readonly __typename: "TransactionVerifyEmailMutationPayload";
  } | null;
};
export type verifyEmailHandlerMutation = {
  response: verifyEmailHandlerMutation$data;
  variables: verifyEmailHandlerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TransactionVerifyEmailMutationPayload",
    "kind": "LinkedField",
    "name": "transactionVerifyEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "verifyEmailHandlerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "verifyEmailHandlerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/491.0.0-2024-12-04T08:42:30.904Z/verifyEmailHandlerMutation",
    "metadata": {},
    "name": "verifyEmailHandlerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "038df1e8cb2211f8d718a9b6dd594b40";

export default node;
